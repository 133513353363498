import React from 'react';
import RobotGenerator from './components/RobotGenerator';

function App() {
  return (
    <div className="App">
      <RobotGenerator />
    </div>
  );
}

export default App;